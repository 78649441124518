import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import * as S from './styles'

const query = graphql`
  {
    prints: file(relativePath: {eq: "shop/prints.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    guitar: file(relativePath: {eq: "guitar/guitar-home.jpeg"}) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Shop = () => {
  const data = useStaticQuery(query)

  return (
    <S.Main>
      <S.ShopWrapper>

        <S.Card left>
          <S.ImgContainer>
            <Img fluid={data.prints.childImageSharp.fluid} />
          </S.ImgContainer>

          <S.TextContainer>
            <h2>
              Old Tree&nbsp;
              <span>Prints</span>
            </h2>
            <p>
              {/* eslint-disable-next-line max-len */}
              Retrouvez ici les articles textiles et les accessoires de la Collection Old Tree Prints.
            </p>

            <S.Button
              target="_blank"
              href="https://www.etsy.com/fr/shop/OldTreeCollective"
              as="a"
            >
              Boutique Prints
            </S.Button>
          </S.TextContainer>
        </S.Card>

        <S.Card>
          <S.ImgContainer>
            <Img fluid={data.guitar.childImageSharp.fluid} />
          </S.ImgContainer>

          <S.TextContainer>
            <h2>
              Old Tree&nbsp;
              <span>Instruments</span>
            </h2>
            <p>
              {/* eslint-disable-next-line max-len */}
              Retrouvez ici les accessoires et instruments de musique de la Collection Old Tree Instruments.
            </p>
            <S.Button
              target="_blank"
              href="https://reverb.com/fr/shop/olds-gear-depot"
              as="a"
            >
              Boutique Instruments
            </S.Button>
          </S.TextContainer>
        </S.Card>

      </S.ShopWrapper>
    </S.Main>
  )
}

export default Shop
