import styled from 'styled-components'
import { Btn } from '../Button'

export const Main = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export const ShopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4rem;

  @media (min-width: 768px) {
    grid-column-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    
  }
`

export const ImgContainer = styled.div`
  width: 300px;
  height: 300px;
`

export const Card = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
row-gap: 1rem;

@media (min-width: 768px) {
    column-gap: 2rem;
    align-items: ${({ left }) => left ? 'flex-end' : 'flex-start'};;
    
}
`

export const TextContainer = styled.div`
    h2 {
        font-size: ${({ theme: { size } }) => size.fontSize.xl};
        max-width: 300px;

        span {
            color: ${({ theme: { color } }) => color.secondary.main};
            font-weight: normal;
        }
    }

    p {
        font-size: ${({ theme: { size } }) => size.fontSize.m};
        max-width: 300px;
        margin-top: 1rem;
        text-align: justify;
    }
`

export const Button = styled(Btn)`
    margin-top: 1rem;

    /* Override base style */
    text-transform: none !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    width: fit-content !important;
    min-width: unset !important;
`
