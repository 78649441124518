import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../SEO'

import Shop from '../components/Shop'

export const query = graphql`
  {
    file(relativePath: { eq: "boutique-home.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const ShopPage = ({ data }) => (
  <Layout withPadding>
    <SEO
      title="Boutique"
      description="Tous nos produits en vente"
      image={data.file.childImageSharp.resize}
    />
    <Shop />
  </Layout>
)

ShopPage.propTypes = {
  data: shape({}).isRequired,
}

export default ShopPage
